<template>
  <HeroBg
    heroTitle="Elite offshore recruitment talent to support  <br class='hidden xl:inline-block' /> your hiring team"
    heroDesc="CareerBuddy embeds the best offshore Recruiters into your company. Scale  <br class='hidden xl:inline-block' /> your business faster, smarter and save you more than 70% in Hiring Fees.."
    heroBtnText="Get Started"
    heroBtnLink="/contact"
    heroImg="hero8.png"
  />

  <Customers />

  <div class="flex justify-center bg-[#FFF8E7]">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center text-[20px] md:text-[40px] text-brand-black leading-[110%] font-semibold mb-12">Hear what companies are saying</h2>

        <div class="px-8 mb-12">
          <div class="h-[1px] bg-brand-black/20"></div>
        </div>

        <div class="md:flex gap-8 pb-5 justify-center">
          <div class="group lg:w-1/3 bg-white hover:bg-white/95 rounded-2xl p-8 mb-6 md:mb-0 transition-all duration-300 hover:shadow-lg">
            <div class="flex items-start gap-4 mb-6">
              <div class="shrink-0">
                <img src="@/assets/images/testify4.png" class="w-[56px] h-[56px] rounded-full object-cover" alt="AAMR profile" />
              </div>
              <div>
                <h3 class="font-semibold text-[16px] text-brand-black mb-1">AAMR</h3>
                <p class="text-[14px] text-[#475467]">HEAD OF STRATEGY OF REMOTEPASS</p>
              </div>
            </div>

            <div class="relative">
              <svg
                class="absolute -top-3 -left-2 w-8 h-8 text-brand-black/10"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003z"
                />
              </svg>

              <p class="text-[#475467] leading-[160%] text-[15px]">
                "I can confidently say that partnering with CareerBuddy's Embedded Talent Partner service has been a game-changer for our recruitment
                process at Remotepass. As a remote company, it can be challenging to find the right talent in different parts of the world. But, with
                CareerBuddy's help, we've been able to streamline our hiring process, reduce our costs, and find high-quality candidates quickly and
                efficiently."
              </p>
            </div>
          </div>

          <div class="group lg:w-1/3 bg-white hover:bg-white/95 rounded-2xl p-8 mb-6 md:mb-0 transition-all duration-300 hover:shadow-lg">
            <div class="flex items-start gap-4 mb-6">
              <div class="shrink-0">
                <img src="@/assets/images/testify5.png" class="w-[56px] h-[56px] rounded-full object-cover" alt="EBI profile" />
              </div>
              <div>
                <h3 class="font-semibold text-[16px] text-brand-black mb-1">EBI</h3>
                <p class="text-[14px] text-[#475467]">HR DIRECTOR OF SABI</p>
              </div>
            </div>

            <div class="relative">
              <svg
                class="absolute -top-3 -left-2 w-8 h-8 text-brand-black/10"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003z"
                />
              </svg>

              <p class="text-[#475467] leading-[160%] text-[15px]">
                "Finding top talent in a highly competitive market can be daunting, but with CareerBuddy's help, we were able to attract and hire the
                best fit for our team. Their professionalism and attention to detail ensured we got candidates that matched our company's culture and
                values"
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-12 text-[20px] md:text-[40px] text-brand-black leading-[110%] font-semibold">Our Global Outsourcing Offering</h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 pb-5">
          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/price.png" class="w-[28px] h-[28px]" alt="Cost icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">Cost Benefits</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              Save up to 80% in hiring cost and paying full-time in house recruiter. Our service offers huge savings per hire versus traditional
              recruitment services. Our flexible plans help you hire when demand is high
            </p>
          </div>

          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/spring.png" class="w-[28px] h-[28px]" alt="Flexibility icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">Maximum Flexibility</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              Short-term 12-week contracts are available. Our team can jump in when needed and leave once roles are filled.
            </p>
          </div>

          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/user.png" class="w-[28px] h-[28px]" alt="Candidates icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">Better Candidates</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              As a CareerBuddy Talent Partner works internally with you, they have a far better understanding of your Hiring Manager's needs as well
              as your company fit, resulting in hiring better talent who will stay with you longer
            </p>
          </div>

          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/adjust.png" class="w-[28px] h-[28px]" alt="Control icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">More Control</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              We combine our technical expertise & specialist support teams with winning technology-led strategies to help you scale. Receive
              pre-screened, vetted, and qualified candidates ready to go
            </p>
          </div>

          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/engineering.png" class="w-[28px] h-[28px]" alt="Technology icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">Leading Technology</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              We combine our technical expertise & specialist support teams with winning technology-led strategies to help you scale. Receive
              pre-screened, vetted, and qualified candidates ready to go
            </p>
          </div>

          <div class="group mb-6 md:mb-0 bg-[#FFF8E7] hover:bg-[#FFE5A3]/30 rounded-2xl p-8 transition-all duration-300">
            <div
              class="w-[52px] h-[52px] rounded-xl bg-white shadow-sm flex justify-center items-center mb-6 group-hover:scale-110 transition-transform duration-300"
            >
              <img src="@/assets/images/icons/people.png" class="w-[28px] h-[28px]" alt="Teams icon" />
            </div>
            <p class="font-bold text-[18px] md:text-[22px] mb-4 leading-[120%] text-brand-black">Embedded Teams</p>
            <p class="text-[14px] md:text-[15px] leading-[150%] text-[#475467]">
              Unlike traditional recruitment specialists, our Talent Partners work as an extended part of your HR team. We integrate seamlessly with
              existing teams and key stakeholders. We help you build better, more diverse recruitment campaigns.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-[#FFF8E7] relative overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#FFE5A3]/20 to-transparent"></div>

    <div class="w-full px-[24px] md:px-0 md:w-[78vw] lg:px-0 relative z-10">
      <div class="py-[5rem] pb-[0rem] lg:pb-[5rem]">
        <div class="grid-cols-1 lg:grid lg:grid-cols-2 gap-16 items-center pb-5">
          <div class="max-w-[600px]">
            <div class="inline-block px-4 py-2 bg-brand-black/5 rounded-full mb-6">
              <span class="text-[14px] font-medium text-brand-black">Our Unique Approach</span>
            </div>

            <h1 class="text-brand-black text-[28px] md:text-[48px] leading-[120%] font-semibold mb-6">
              The key to success?
              <br class="hidden md:inline-block" />
              <span class="text-[#B45309]">Our "embed model"</span>
            </h1>

            <p class="text-[16px] md:text-[20px] leading-[150%] text-[#475467] mb-8">
              We are not only providing talent, we are fully embedding a new team member into your company
            </p>

            <div class="flex flex-col md:flex-row gap-4 md:items-center">
              <a
                href="/contact"
                class="inline-flex items-center justify-center px-6 py-3 bg-brand-black text-white rounded-xl hover:bg-brand-black/90 transition-all group"
              >
                <span class="mr-2">Get Started</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="w-4 h-4 transition-transform group-hover:translate-x-0.5"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg>
              </a>

              <a
                href="/about-us"
                class="inline-flex items-center justify-center px-6 py-3 border border-brand-black/10 text-brand-black rounded-xl hover:bg-brand-black/5 transition-all"
              >
                Learn more about our model
              </a>
            </div>
          </div>

          <div class="hidden lg:flex justify-end">
            <div class="relative">
              <div class="absolute -inset-1 bg-gradient-to-r from-[#FFE5A3] to-[#FFF8E7] blur-2xl opacity-30"></div>
              <img
                src="@/assets/images/embed.png"
                alt="Talent embedding illustration"
                class="relative w-full max-w-[500px] object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <div class="px-[24px] md:px-0 max-w-[900px] mx-auto">
          <!-- <div class="inline-block px-4 py-2 bg-brand-black/5 rounded-full mb-4 mx-auto">
            <span class="text-[14px] font-medium text-brand-black">Simple Process</span>
          </div> -->

          <h2 class="text-center text-[28px] md:text-[48px] text-brand-black leading-[120%] font-semibold mb-6">
            How it <span class="text-[#B45309]">works</span>
          </h2>

          <p class="text-[#475467] text-[16px] md:text-[20px] text-center leading-[150%] mb-12 max-w-[700px] mx-auto">
            When you partner with CareerBuddy, we handle the entire recruitment process.
            <span class="font-medium">No resume screening or interviews needed</span> – we own this process 100%!
          </p>
        </div>

        <StepsLight />
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem] pt-[1rem] md:pt-[5rem]">
        <div class="md:grid md:grid-cols-2 gap-8 pb-5">
          <div class="group mb-4 md:mb-0 bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 transition-all duration-300 border border-brand-black/5">
            <div
              class="w-[72px] h-[72px] rounded-2xl bg-[#FFF8E7] flex justify-center items-center mb-6 group-hover:scale-110 transition-all duration-300"
            >
              <img src="@/assets/images/icons/time.png" class="w-[42px] h-[42px]" alt="Full time icon" />
            </div>
            <h3 class="text-[20px] md:text-[22px] leading-[120%] text-brand-black font-semibold mb-4">Full Time</h3>
            <p class="text-[14px] md:text-[16px] leading-[150%] text-[#475467]">
              Tell us about the role you're looking to fill. We'll get to know each other and understand your specific requirements.
            </p>
          </div>

          <div class="group mb-4 md:mb-0 bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 transition-all duration-300 border border-brand-black/5">
            <div
              class="w-[72px] h-[72px] rounded-2xl bg-[#FFF8E7] flex justify-center items-center mb-6 group-hover:scale-110 transition-all duration-300"
            >
              <img src="@/assets/images/icons/globe.png" class="w-[42px] h-[42px]" alt="Time zone icon" />
            </div>
            <h3 class="text-[20px] md:text-[22px] leading-[120%] text-brand-black font-semibold mb-4">In Your Time Zone</h3>
            <p class="text-[14px] md:text-[16px] leading-[150%] text-[#475467]">
              They work during your normal business hours – ensuring seamless collaboration despite any time differences.
            </p>
          </div>

          <div class="group mb-4 md:mb-0 bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 transition-all duration-300 border border-brand-black/5">
            <div
              class="w-[72px] h-[72px] rounded-2xl bg-[#FFF8E7] flex justify-center items-center mb-6 group-hover:scale-110 transition-all duration-300"
            >
              <img src="@/assets/images/icons/hand.png" class="w-[42px] h-[42px]" alt="Team member icon" />
            </div>
            <h3 class="text-[20px] md:text-[22px] leading-[120%] text-brand-black font-semibold mb-4">Fully Integrated Team Member</h3>
            <p class="text-[14px] md:text-[16px] leading-[150%] text-[#475467]">
              Complete access to your company's tools and culture – from email and Slack to team meetings and virtual celebrations.
            </p>
          </div>

          <div class="group mb-4 md:mb-0 bg-white hover:bg-[#FFF8E7] rounded-2xl p-8 transition-all duration-300 border border-brand-black/5">
            <div
              class="w-[72px] h-[72px] rounded-2xl bg-[#FFF8E7] flex justify-center items-center mb-6 group-hover:scale-110 transition-all duration-300"
            >
              <img src="@/assets/images/icons/handshake.png" class="w-[42px] h-[42px]" alt="Support icon" />
            </div>
            <h3 class="text-[20px] md:text-[22px] leading-[120%] text-brand-black font-semibold mb-4">Comprehensive Support</h3>
            <p class="text-[14px] md:text-[16px] leading-[150%] text-[#475467]">
              We provide unlimited ongoing support to ensure your success. Our commitment is simple: we succeed when you succeed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <faqs :faqs="faqs" />
</template>

<script setup>
import { ref } from 'vue';
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import Faqs from '@/components/partials/Faqs.vue';
import StepsLight from '@/components/partials/StepsLight.vue';
import { useMeta } from '@/utils/meta';

const faqs = ref([
  {
    title: 'How does the Embedded Talent Partner service work?',
    desc: `<p>Our Talent Acquisition Specialists work in partnership with your HR team. We manage the recruiter on your behalf, ensuring consistency of service at all times. We agree on deliverables ahead of time and we deliver what we promise.</p>

    <p>Talent Acquisition Specialists focus on sourcing, screening, managing inbound applications, candidate management, and screen on average between 10-20 candidates per role. We manage the recruiter on your behalf, ensuring consistency of service at all times.</p>

      <p>Embedded Talent Partners plugin to your talent team. They are focused on your delivery 9am-6pm Monday-Friday. You’ll find they’ll help you hire better talent, faster, whilst eradicating agency fees.</p>`,
  },
  {
    title: 'How Does the Embedded Talent Partner differ from your regular Hiring Service',
    desc: `<p>This model differs based on how you pay. With our Hiring Service, you pay a commission on success. While this removes risk, it is expensive. With the Embedded Talent Partner, you get the same quality of service at a fraction of the cost.</p>

    <p>We help Business leaders and HR managers succeed. We offer a dedicated monthly subscription model, allowing you to scale quickly by freeing you up to do what you do best.</p>`,
  },
  {
    title: "What's your payment structure?",
    desc: `<p>We charge an all-inclusive flat monthly subscription with no additional fees upon placement. The benefit to our clients includes accurate forecasting, increased budget efficiencies, less downtime, on-demand expertise, and resources. </p>

    <p>Our Embedded Partner supports you when you need it, how you need it, and where you need it. Our model makes it easier for clients to plan ahead and ensures we are working in line with your business goals.</p>`,
  },
]);

useMeta();
</script>

<style lang="scss" scoped>
.faq-desc::v-deep {
  p {
    line-height: 25.999px !important;
    font-size: 16px !important;
    margin-bottom: 1rem;
  }
}
</style>
