<template>
  <div class="space-y-6">
    <!-- Step 1 -->
    <div class="bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-lg">
      <div class="md:grid md:grid-cols-[1fr,320px] items-center gap-12">
        <div>
          <div class="flex items-center gap-3 mb-5">
            <span class="px-3 py-1.5 bg-[#C1FFE9] rounded-md text-[13px] font-medium text-emerald-800">STEP 1</span>
            <span class="h-[1px] w-12 bg-emerald-100"></span>
          </div>

          <h1 class="text-brand-black font-bold text-[22px] md:text-[26px] mb-3">Matching</h1>

          <p class="text-[#475467] text-[15px] leading-[150%] mb-6">
            Extend job offers to matched talent and onboard. We recruit a Growth Assistant (GA) to fit your exact needs, working hours, etc. This step
            takes about 14 days.
          </p>

          <a
            href="https://employer.thecareerbuddy.com/register"
            class="group rounded-xl px-6 py-3 text-center bg-brand-black text-white inline-flex items-center hover:bg-brand-black/90 transition-all"
          >
            <span class="mr-2 text-[15px]">Get Started</span>
            <img src="@/assets/icons/arrow-right.svg" alt="" class="w-4 h-4 transition-transform group-hover:translate-x-0.5" />
          </a>
        </div>

        <div class="hidden md:block">
          <img src="@/assets/images/team.png" alt="Team illustration" class="w-full h-auto object-contain" />
        </div>
      </div>
    </div>

    <!-- Step 2 -->
    <div class="bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-lg">
      <div class="md:grid md:grid-cols-[1fr,320px] items-center gap-12">
        <div>
          <div class="flex items-center gap-3 mb-5">
            <span class="px-3 py-1.5 bg-[#C1FFE9] rounded-md text-[13px] font-medium text-emerald-800">STEP 2</span>
            <span class="h-[1px] w-12 bg-emerald-100"></span>
          </div>

          <h1 class="text-brand-black font-bold text-[22px] md:text-[26px] mb-3">Get matched with vetted talents</h1>

          <p class="text-[#475467] text-[15px] leading-[150%] mb-6">
            Meet top quality talent that matches your hiring request. Conduct interviews & further assessments if necessary.
          </p>

          <a
            href="https://employer.thecareerbuddy.com//register"
            class="group rounded-xl px-6 py-3 text-center bg-brand-black text-white inline-flex items-center hover:bg-brand-black/90 transition-all"
          >
            <span class="mr-2 text-[15px]">Get Started</span>
            <img src="@/assets/icons/arrow-right.svg" alt="" class="w-4 h-4 transition-transform group-hover:translate-x-0.5" />
          </a>
        </div>

        <div class="hidden md:block">
          <img src="@/assets/images/hiring.png" alt="Hiring illustration" class="w-full h-auto object-contain" />
        </div>
      </div>
    </div>

    <!-- Step 3 -->
    <div class="bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-lg">
      <div class="md:grid md:grid-cols-[1fr,320px] items-center gap-12">
        <div>
          <div class="flex items-center gap-3 mb-5">
            <span class="px-3 py-1.5 bg-[#C1FFE9] rounded-md text-[13px] font-medium text-emerald-800">STEP 3</span>
            <span class="h-[1px] w-12 bg-emerald-100"></span>
          </div>

          <h1 class="text-brand-black font-bold text-[22px] md:text-[26px] mb-3">Hire without overhead</h1>

          <p class="text-[#475467] text-[15px] leading-[150%] mb-6">
            Extend job offers to matched talent and onboard. We recruit a Growth Assistant (GA) to fit your exact needs, working hours, etc. This step
            takes about 14 days.
          </p>

          <a
            href="https://employer.thecareerbuddy.com//register"
            class="group rounded-xl px-6 py-3 text-center bg-brand-black text-white inline-flex items-center hover:bg-brand-black/90 transition-all"
          >
            <span class="mr-2 text-[15px]">Get Started</span>
            <img src="@/assets/icons/arrow-right.svg" alt="" class="w-4 h-4 transition-transform group-hover:translate-x-0.5" />
          </a>
        </div>

        <div class="hidden md:block">
          <img src="@/assets/images/globe.png" alt="Globe illustration" class="w-full h-auto object-contain" />
        </div>
      </div>
    </div>
  </div>
</template>
